/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

function reloadClinicMindsScript() {
  const oldScript = document.querySelector(
    'script[src="https://schedule.clinicminds.com/assets/widget/widget.js"]'
  )
  if (oldScript) {
    oldScript.remove()
    console.log("[ClinicMinds] Removed old script.")
  }

  const script = document.createElement("script")
  script.src = "https://schedule.clinicminds.com/assets/widget/widget.js"
  script.type = "text/javascript"
  script.onload = () => {
    console.log("[ClinicMinds] Reloaded script.")
  }
  document.body.appendChild(script)
}

export const onRouteUpdate = () => {
  console.log("[ClinicMinds] Route updated.")
  reloadClinicMindsScript()
}
